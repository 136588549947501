import { Select } from "@mui/material";
import { Col, Container, Row, Form, Button, Modal } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { apiService } from "../service/api.service";
import swal from "sweetalert";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Web3 from "web3";
import { adConfig, gasFeePercent, nftBundlingBuyingFee, nftBundlingContract, nftBundlingRelistingFee, nftBundlingUpdatePricingFee } from "../config/config";
import NFT_BUNDLING_ABI from "../common/NFT_BUNDLING_ABI.json"
import { ethers } from "ethers";
import { FundAddModal } from "../common/FundAddModal";
import AdComponent from "../common/AdComponent";
import { ThreeDotSpinner } from "./loader";

const MarketplaceDetails = () => {
  const { tokenId } = useParams();

  const [showLoader, setShowLoader] = useState(true);
  const [marketplaceNft, setMarketplaceNft] = useState();
  const walletAddress = useSelector((state) => state.reducer.walletAddress)
  const web3AuthProvider = useSelector((state) => state.reducer.web3AuthProvider);
  const [showFollowSteps, setShowFollowSteps] = useState(false);
  const [showFollowStepsForSell, setShowFollowStepsForSell] = useState(false);
  const [showFollowStepsForUpdatePrice, setShowFollowStepsForUpdatePrice] = useState(false);

  const adDataFollowSteps = adConfig["PredictionDetail"]; // Get specific page ad config
  const [showNftSellingPriceModal, setShowNftSellingPriceModal] = useState(false);
  const [nftSellingPrice, setNftSellingPrice] = useState('');
  const [showNftPriceUpdateModal, setShowNftPriceUpdateModal] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    getMarketplaceNftDetails(tokenId);
  }, [])

  async function getMarketplaceNftDetails(tokenId) {
    try {
      const response = await apiService.getMarketplaceNftDetails(tokenId);
      if (response.status === 200) {
        setMarketplaceNft(response?.data?.data);
        setShowLoader(false);
      }
    } catch (error) {
      setShowLoader(false);
      if (error?.response?.status === 401) {
        swal({ text: "Unauthorized", button: "OK" }).then(() => {
          localStorage.clear();
          window.location.href = "/";
        });
      }
    }
  }

  async function bundlingNftBuy() {
    try {
      const nftBuyingFee = nftBundlingBuyingFee * 10 ** 18;
      let nftPrice = nftBuyingFee + Number(marketplaceNft?.price);
      nftPrice = nftPrice.toString();
      setShowFollowSteps(true);
      const web3 = new Web3(web3AuthProvider);
      let nftContract = new web3.eth.Contract(NFT_BUNDLING_ABI, nftBundlingContract);
      if (localStorage.getItem("user_type") == "CUSTODIAL") {
        const ethersProvider = new ethers.providers.Web3Provider(web3AuthProvider);
        const signer = ethersProvider.getSigner();
        nftContract = new ethers.Contract(nftBundlingContract, NFT_BUNDLING_ABI, signer);
        const balance = await ethersProvider.getBalance(walletAddress);
        let balacneInETH = ethers.utils.formatEther(balance);
        if (Number(balacneInETH) < ((Number(marketplaceNft?.price) / 10 ** 18) + (nftBundlingBuyingFee + 0.1))) {
          await FundAddModal();
          setShowFollowSteps(false);
        } else {
          await swal({
            text: "Are you sure you want to complete this transaction?",
            icon: "info",
            buttons: ["Cancel", "Confirm"]
          }).then(async (res) => {
            if (res) {
              const tx = await nftContract.buyNFT(tokenId, {
                gasLimit: 500000, // Fallback if gas estimation fails
                value: nftPrice
              });
              const receipt = await tx.wait();
              if (receipt) {
                console.log("==========Transaction successful, receipt:", receipt);
                swal("Success", "NFT buy successfully.", "success").then(res => {
                  window.location.reload();
                });
                setShowFollowSteps(false);
              } else {
                swal("Error", "Something went wrong.", "error").then(res => {
                  window.location.reload();
                });
              }
            } else {
              setShowFollowSteps(false);
            }
          })
        }
      } else {
        let gas, gasPrice;
        try {
          gasPrice = await web3.eth.getGasPrice();
          gasPrice = Math.ceil(Number(gasPrice) * gasFeePercent);
          gas = await nftContract.methods.buyNFT(tokenId).estimateGas({ from: walletAddress, value: nftPrice });
          gas = Math.ceil(Number(gas) * gasFeePercent);
        } catch (error) {

        }

        const receipt = await nftContract.methods.buyNFT(tokenId).send({
          from: walletAddress,
          value: nftPrice,
          gas: gas,  // Dynamically estimated gas
          gasPrice: gasPrice  // Current network gas price
        });
        console.log("==========Transaction successful, receipt:", receipt);
        swal("Success", "NFT buy successfully.", "success").then(res => {
          window.location.reload();
        });
        setShowFollowSteps(false);
      }
    } catch (error) {
      console.log("error ajit", error);
      setShowFollowSteps(false);
      swal("Error", "Something went wrong.", "error").then(res => {
        window.location.reload();
      });
    }
  }


  const handleNftSellSubmit = () => {
    if (nftSellingPrice.trim() === '' || isNaN(nftSellingPrice) || Number(nftSellingPrice) <= 0) {
      swal({
        // title: "Error!",
        text: "Please enter a valid price!",
        icon: "info",
        button: "OK",
      })
      return;
    }
    setShowNftSellingPriceModal(false); // Close modal
    bundlingNftSell();
  };


  async function bundlingNftSell() {
    try {
      let nftRelistingFee = nftBundlingRelistingFee * 10 ** 18;
      nftRelistingFee = nftRelistingFee.toString();
      let sellingPrice = nftSellingPrice * 10 ** 18;
      sellingPrice = sellingPrice.toString();
      setShowFollowStepsForSell(true);
      const web3 = new Web3(web3AuthProvider);
      let nftContract = new web3.eth.Contract(NFT_BUNDLING_ABI, nftBundlingContract);
      if (localStorage.getItem("user_type") == "CUSTODIAL") {
        const ethersProvider = new ethers.providers.Web3Provider(web3AuthProvider);
        const signer = ethersProvider.getSigner();
        nftContract = new ethers.Contract(nftBundlingContract, NFT_BUNDLING_ABI, signer);
        const balance = await ethersProvider.getBalance(walletAddress);
        let balacneInETH = ethers.utils.formatEther(balance);
        if (Number(balacneInETH) < (nftBundlingRelistingFee + 0.1)) {
          await FundAddModal();
          setShowFollowStepsForSell(false);
        } else {
          await swal({
            text: "Are you sure you want to complete this transaction?",
            icon: "info",
            buttons: ["Cancel", "Confirm"]
          }).then(async (res) => {
            if (res) {
              const tx = await nftContract.relistNFT(tokenId, sellingPrice, {
                gasLimit: 500000, // Fallback if gas estimation fails
                value: nftRelistingFee
              });
              const receipt = await tx.wait();
              if (receipt) {
                console.log("==========Transaction successful, receipt:", receipt);
                swal("Success", "NFT buy successfully.", "success").then(res => {
                  window.location.reload();
                });
                setShowFollowStepsForSell(false);
              } else {
                swal("Error", "Something went wrong.", "error").then(res => {
                  window.location.reload();
                });
              }
            } else {
              setShowFollowStepsForSell(false);
            }
          })
        }
      } else {
        let gas, gasPrice;
        try {
          gasPrice = await web3.eth.getGasPrice();
          gasPrice = Math.ceil(Number(gasPrice) * gasFeePercent);
          gas = await nftContract.methods.relistNFT(tokenId, sellingPrice).estimateGas({ from: walletAddress, value: nftRelistingFee });
          gas = Math.ceil(Number(gas) * gasFeePercent);
        } catch (error) {

        }

        const receipt = await nftContract.methods.relistNFT(tokenId, sellingPrice,).send({
          from: walletAddress,
          value: nftRelistingFee,
          gas: gas,  // Dynamically estimated gas
          gasPrice: gasPrice  // Current network gas price
        });
        console.log("==========Transaction successful, receipt:", receipt);
        swal("Success", "NFT buy successfully.", "success").then(res => {
          window.location.reload();
        });
        setShowFollowStepsForSell(false);
      }
    } catch (error) {
      console.log("error ajit", error);
      setShowFollowStepsForSell(false);
      swal("Error", "Something went wrong.", "error").then(res => {
        window.location.reload();
      });
    }
  }


  const handleNftPriceUpdateSubmit = () => {
    if (nftSellingPrice.trim() === '' || isNaN(nftSellingPrice) || Number(nftSellingPrice) <= 0) {
      swal({
        // title: "Error!",
        text: "Please enter a valid price!",
        icon: "info",
        button: "OK",
      })
      return;
    }
    setShowNftPriceUpdateModal(false); // Close modal
    bundlingNftPriceUpdate();
  };


  async function bundlingNftPriceUpdate() {
    try {
      let nftRelistingFee = nftBundlingUpdatePricingFee * 10 ** 18;
      nftRelistingFee = nftRelistingFee.toString();
      let sellingPrice = nftSellingPrice * 10 ** 18;
      sellingPrice = sellingPrice.toString();
      setShowFollowStepsForUpdatePrice(true);
      const web3 = new Web3(web3AuthProvider);
      let nftContract = new web3.eth.Contract(NFT_BUNDLING_ABI, nftBundlingContract);
      if (localStorage.getItem("user_type") == "CUSTODIAL") {
        const ethersProvider = new ethers.providers.Web3Provider(web3AuthProvider);
        const signer = ethersProvider.getSigner();
        nftContract = new ethers.Contract(nftBundlingContract, NFT_BUNDLING_ABI, signer);
        const balance = await ethersProvider.getBalance(walletAddress);
        let balacneInETH = ethers.utils.formatEther(balance);
        if (Number(balacneInETH) < (nftBundlingRelistingFee + 0.1)) {
          await FundAddModal();
          setShowFollowStepsForUpdatePrice(false);
        } else {
          await swal({
            text: "Are you sure you want to complete this transaction?",
            icon: "info",
            buttons: ["Cancel", "Confirm"]
          }).then(async (res) => {
            if (res) {
              const tx = await nftContract.updateNFTPrice(tokenId, sellingPrice, {
                gasLimit: 500000, // Fallback if gas estimation fails
                value: nftRelistingFee
              });
              const receipt = await tx.wait();
              if (receipt) {
                console.log("==========Transaction successful, receipt:", receipt);
                swal("Success", "NFT price update successfully.", "success").then(res => {
                  window.location.reload();
                });
                setShowFollowStepsForUpdatePrice(false);
              } else {
                swal("Error", "Something went wrong.", "error").then(res => {
                  window.location.reload();
                });
              }
            } else {
              setShowFollowStepsForUpdatePrice(false);
            }
          })
        }
      } else {
        let gas, gasPrice;
        try {
          gasPrice = await web3.eth.getGasPrice();
          gasPrice = Math.ceil(Number(gasPrice) * gasFeePercent);
          gas = await nftContract.methods.updateNFTPrice(tokenId, sellingPrice).estimateGas({ from: walletAddress, value: nftRelistingFee });
          gas = Math.ceil(Number(gas) * gasFeePercent);
        } catch (error) {

        }

        const receipt = await nftContract.methods.updateNFTPrice(tokenId, sellingPrice,).send({
          from: walletAddress,
          value: nftRelistingFee,
          gas: gas,  // Dynamically estimated gas
          gasPrice: gasPrice  // Current network gas price
        });
        console.log("==========Transaction successful, receipt:", receipt);
        swal("Success", "NFT price update successfully.", "success").then(res => {
          window.location.reload();
        });
        setShowFollowStepsForUpdatePrice(false);
      }
    } catch (error) {
      console.log("error ajit", error);
      setShowFollowStepsForUpdatePrice(false);
      swal("Error", "Something went wrong.", "error").then(res => {
        window.location.reload();
      });
    }
  }


  return (
    <>
      {showLoader ? <ThreeDotSpinner /> : ""}
      <section className="marketplace-section profile-details-section">
        <Container>
          <div className="detail-top-area">
            <div className="back-top-btn">
              <button type="button" className="btn btn-unset" onClick={()=>navigate(-1)}>
                <i className="fa fa-angle-left" aria-hidden="true"></i> Back
              </button>
            </div>
          </div>
          <div className="marketplace-content-area">
            <Row className="mb-5">
              <Col md={12} lg={5} xl={7}>
                <h2>NFT Detail Page</h2>
              </Col>
            </Row>
          </div>
          {/* marketplace-area */}
          <div className="marketplace-area">
            <Row>

              {marketplaceNft?.predictions?.map(item => {

                return (<Col sm={12} md={6} lg={4} xl={4} className="margin-btm">
                  <div className="marketplace-outer position-relative marketplace-under-details">
                    <div className="marketplace-header marketplace-detail">
                      <img src={item?.image} />
                    </div>
                    <div className="marketplace-body">
                      <h6>
                        Prediction Name:{" "}
                        <span>{item?.question}</span>
                      </h6>
                      <h6>
                        Selected Option: <span>{item?.madepredictions?.map(innerItem=> innerItem.prediction)}</span>
                      </h6>
                      <h6>
                        Prediction Type: <span>{item?.data_provider}</span>
                      </h6>
                      <h6>
                      Contract Address: <span>{item?.contract_address.split("_")[0]}</span>
                      </h6>
                      <h6>
                        Prize Pool: <span>{marketplaceNft?.price / 10 ** 18} POL</span>
                      </h6>
                    </div>
                    {/* <img className="stone-img" src={require("../assets/images/stone-1.png")} /> */}
                  </div>
                </Col>)
              })}
            </Row>
            <div className="buy-now">
              {(new Date() >= new Date(new Date(marketplaceNft?.predictions?.[0]?.endDateTime).getTime() - 10 * 60 * 1000)) ? "":marketplaceNft?.sold && walletAddress && marketplaceNft?.owner != `${walletAddress}`?.toLowerCase() ?
                <button type="button" class="viw-more-btn btn btn-unset">Sold</button> :
                !(marketplaceNft?.sold) && walletAddress && marketplaceNft?.owner == `${walletAddress}`?.toLowerCase() ?
                  <button type="button" class="viw-more-btn btn btn-unset" onClick={() => setShowNftPriceUpdateModal(true)}>Update Price</button> :
                  walletAddress && marketplaceNft?.sold && marketplaceNft?.owner == `${walletAddress}`?.toLowerCase() ?
                    <button type="button" class="viw-more-btn btn btn-unset" onClick={() => setShowNftSellingPriceModal(true)}>Sell</button> :
                    marketplaceNft && walletAddress ? <button type="button" class="viw-more-btn btn btn-unset" onClick={() => bundlingNftBuy()}>Buy</button> :
                      ""}
            </div>
          </div>
        </Container>
      </section>

      <Modal
        show={showFollowSteps}
        backdrop="static"
        onHide={() => setShowFollowSteps(false)}
        centered
        className="connect-wallet-box follow-steps-popup"
      >
        <Modal.Header>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h3 className="mt-0">Follow Steps </h3>
          {window.innerWidth <= 768 ? (
            <AdComponent adId={adDataFollowSteps.ids[2]} dimension={adDataFollowSteps.dimensions[2]} className="follow-steps-ad-mid" />
          ) : (
            <AdComponent adId={adDataFollowSteps.ids[3]} dimension={adDataFollowSteps.dimensions[3]} className="follow-steps-ad-mid" />
          )}

          <div className="step-area">
            <div className="steps-left-area">
              {showFollowSteps ? <div class="loader"></div> : <h2>✓</h2>}
            </div>
            <div className="steps-content">
              <h6>Buy NFT</h6>
              <p>Send transaction to Buy NFT</p>
            </div>
          </div>

        </Modal.Body>
      </Modal>

      <Modal
        show={showFollowStepsForSell}
        backdrop="static"
        onHide={() => setShowFollowStepsForSell(false)}
        centered
        className="connect-wallet-box follow-steps-popup"
      >
        <Modal.Header>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h3 className="mt-0">Follow Steps </h3>
          {window.innerWidth <= 768 ? (
            <AdComponent adId={adDataFollowSteps.ids[2]} dimension={adDataFollowSteps.dimensions[2]} className="follow-steps-ad-mid" />
          ) : (
            <AdComponent adId={adDataFollowSteps.ids[3]} dimension={adDataFollowSteps.dimensions[3]} className="follow-steps-ad-mid" />
          )}

          <div className="step-area">
            <div className="steps-left-area">
              {showFollowStepsForSell ? <div class="loader"></div> : <h2>✓</h2>}
            </div>
            <div className="steps-content">
              <h6>Sell NFT</h6>
              <p>Send transaction to Sell NFT</p>
            </div>
          </div>

        </Modal.Body>
      </Modal>


      <Modal
        show={showFollowStepsForUpdatePrice}
        backdrop="static"
        onHide={() => setShowFollowStepsForUpdatePrice(false)}
        centered
        className="connect-wallet-box follow-steps-popup"
      >
        <Modal.Header>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h3 className="mt-0">Follow Steps </h3>
          {window.innerWidth <= 768 ? (
            <AdComponent adId={adDataFollowSteps.ids[2]} dimension={adDataFollowSteps.dimensions[2]} className="follow-steps-ad-mid" />
          ) : (
            <AdComponent adId={adDataFollowSteps.ids[3]} dimension={adDataFollowSteps.dimensions[3]} className="follow-steps-ad-mid" />
          )}

          <div className="step-area">
            <div className="steps-left-area">
              {showFollowStepsForUpdatePrice ? <div class="loader"></div> : <h2>✓</h2>}
            </div>
            <div className="steps-content">
              <h6>NFT price update</h6>
              <p>Send transaction to price update NFT</p>
            </div>
          </div>

        </Modal.Body>
      </Modal>


      <Modal show={showNftSellingPriceModal} onHide={() => { setShowNftSellingPriceModal(false); setNftSellingPrice("") }} centered>
        <Modal.Header closeButton>
          <Modal.Title>NFT Selling Price</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="nftPriceInput">
              <Form.Label>NFT Price</Form.Label>
              <Form.Control
                type="number"
                placeholder="Enter NFT price"
                value={nftSellingPrice}
                onChange={(e) => setNftSellingPrice(e.target.value)}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleNftSellSubmit}>
            Sell NFT
          </Button>
        </Modal.Footer>
      </Modal>



      <Modal show={showNftPriceUpdateModal} onHide={() => { setShowNftPriceUpdateModal(false); setNftSellingPrice("") }} centered>
        <Modal.Header closeButton>
          <Modal.Title>NFT Price Update</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="nftPriceInput">
              <Form.Label>NFT Price Update</Form.Label>
              <Form.Control
                type="number"
                placeholder="Enter NFT price"
                value={nftSellingPrice}
                onChange={(e) => setNftSellingPrice(e.target.value)}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleNftPriceUpdateSubmit}>
            Update Now
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default MarketplaceDetails;
